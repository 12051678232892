export const isLocalPath = (path: string) => path.startsWith("/") && !path.startsWith("/manage-plan");

// Get the root path of a page, e.g. /products/cluck, the root path would be /products
export const getLocalPathRootPage = (path: string, basePath: string | undefined) => {
  let pathToReturn = (basePath && path.startsWith(basePath)) ? path.replace(basePath, "").split("?")[0] : path.split("?")[0];
  pathToReturn = pathToReturn?.split("#")[0];
  return pathToReturn?.split("/")[1];
};

export const isActivePath = (linkPath: string, currentPath: string, basePath: string | undefined) => {
  if (!isLocalPath(linkPath)) return false;
  if (getLocalPathRootPage(linkPath, basePath) === getLocalPathRootPage(currentPath, basePath)) return true;
  return false;
};
